import { Axios } from 'axios';
import {
	fetchGet,
	fetchPost,
	fetchPut,
	fetchDelete,
	SERVER_ENDPOINT,
} from './index';

export const userSignIn = async (user_data) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/users/userSignIn`,
		user_data,
	);
};

export const fetchVerifiedUser = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/users/userVerify`,
	);
};

export const userLogout = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/users/userLogout`,
	);
};

export const addDataRow = async (row) => {
	return await fetchPost(
		`${SERVER_ENDPOINT}/api/data/addDataRow`,
		row,
	);
}

export const getDataTable = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/data/getDataTable`, 
	);
};

export const getDataTableByDepartment = async (department) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/data/getDataByDepartment?department=${department}`,
	);
};

export const downloadDataTable = async () => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/data/downloadTable`,
		'get existing excel file',
	);
};

export const downloadDepartmentDataTable = async (department) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/data/downloadTableByDepartmnent?department=${department}`,
		'get existing excel file',
	);
};

export const updateTableRow = async (data) => {
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/data/updateTableRow`,
		data
	);
};

export const removeTableRow = async (id) => {
	return await fetchDelete(
		`${SERVER_ENDPOINT}/api/data/deleteTableRow?id=${id}`,
	);
};


///
export const getSystemSettingByName = async (name) => {
	return await fetchGet(
		`${SERVER_ENDPOINT}/api/settings/getSystemSettingByName?name=${name}`,
		'',
	);
};

export const updateSystemSetting = async (data) => {
	console.log("api", data)
	return await fetchPut(
		`${SERVER_ENDPOINT}/api/settings/updateSystemSetting`,
		data
	);
};