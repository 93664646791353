import { ThemeProvider } from '@emotion/react';
import { Box, createTheme, experimental_sx as sx } from '@mui/material';
import { useContext } from 'react';
import AppContext from '../../contexts/AppContext';

const { palette } = createTheme();

const theme = createTheme({
    direction: 'rtl',
    palette: {
        primary: {
            main: '#3982e3',
            light: '#609BE8',
            dark: '#2D68B5',
        },
        primary_dark: {
            main: '#0d1857',
            light: '#2e3b8f',
            dark: '#020933',
            contrastText: '#edeffc',
        },
        gray: {
            50: '#edeffc',
            100: '#F5F6F7',
            // 200: '#E0EFF8',
            // 500: '#a5a5a5',
            // 800: '#233B4B',
            700: '#19172b',
        },
    },
    typography: {
        fontFamily: 'Rubik, Arial',
        h1: {
            fontSize: 'max(34px, 3.6vw)',
            fontWeight: 700,
        },
        h2: {
            fontSize: 'max(28px, 2.4vw)',
            fontWeight: 800,
        },
        h3: {
            fontSize: 'max(22px, 2vw)',
            fontWeight: 700,
        },
        p: {
            fontSize: 'max(16px, 1vw)',
        },
        input: {
            fontSize: 'max(16px, 1vw)',
        },
        button: {
            fontSize: 'max(18px, 1.1vw)',
        }
    },
    // components: {
    //     MuiIconButton: {
    //         styleOverrides: {
    //             // background: 'red'
    //             "&:hover": {
    //                 "& .MuiSvgIcon-root":{
    //                     color: 'red',
    //                     fill: 'red'
    //                 }
    //             },
    //         }
    //     },
    // },
});



export default theme;
