import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Slide } from '@mui/material';
import React, {useContext} from 'react';
import AppContext from '../contexts/AppContext';
import Draggable from 'react-draggable';


export const DEFAULT_DIALOG_BUTTONS = {ok:"OK", cancel:"CANCEL"};
export const DEFAULT_DIALOG_PARAMETERS = {title:"Title", description:"Description!", content: null};
export const DEFAULT_DIALOG_HANDLERS = {call_on_submit:null, call_on_cancel:null};
export const HIDDEN_DIALOG_OBJECT = {open:false, ...DEFAULT_DIALOG_HANDLERS, ...DEFAULT_DIALOG_BUTTONS, ...DEFAULT_DIALOG_PARAMETERS};

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }
  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  
const DialogPopupContainer = () => {
  const {dialogObject, setDialogObject} = useContext(AppContext);

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {return;}
    if (reason === 'clickaway') { return; }
    const {call_on_cancel} = dialogObject;
    setDialogObject({...dialogObject, open: false});
    if (call_on_cancel) { call_on_cancel(); }
  };

  const handleSubmit = (event, reason) => {
    event.preventDefault();
    if (reason === 'clickaway') { return; }
    const {call_on_submit} = dialogObject;
    setDialogObject({...dialogObject, open: false});
    if (call_on_submit) { call_on_submit(); }
  };

  return (
    <div>
        <Dialog 
            open={dialogObject.open} 
            onClose={handleClose} 
            TransitionComponent={Transition}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {dialogObject.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {dialogObject.description}
                </DialogContentText>
                <hr />
                {dialogObject.content}
            </DialogContent>
            {dialogObject.ok || dialogObject.cancel ? (
              <DialogActions>
                <hr/>
                <Button onClick={handleClose} variant="contained" color="secondary">
                    {dialogObject.cancel}
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    {dialogObject.ok}
                </Button>
              </DialogActions>
            ) : null}
            
        </Dialog>
    </div>
  );
}

export default DialogPopupContainer;