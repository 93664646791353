import './assets/scss/general.scss';
import {
	Routes,
	BrowserRouter as Router,
	Route,
	Navigate,
	useNavigate,
} from 'react-router-dom';
import { Suspense, lazy } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';
import { CacheProvider, ThemeProvider } from '@emotion/react';
import { useEffect, useState } from 'react';
import { fetchVerifiedUser } from './services/api';
import AlertPopupContainer, {HIDDEN_ALERT_OBJECT} from './helpers/AlertPopup';
import DialogPopupContainer, {HIDDEN_DIALOG_OBJECT} from './helpers/DialogPopup';
import AppContext from './contexts/AppContext';
import theme from './styles/mui/CustomTheme';
import { LinearProgress } from '@mui/material';

const Home = lazy(() => import ('./screens/Home'));
const Panel = lazy(() => import ('./screens/Panel'));
const Login = lazy(() => import ('./screens/Login'));
const Logout  = lazy(() => import ('./screens/Logout'));

const cacheRtl = createCache({
	key: 'muirtl',
	stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
	const [loggedInUser, setLoggedInUser] = useState({});
	const [alertObject, setAlertObject] = useState(HIDDEN_ALERT_OBJECT);
	const [dialogObject, setDialogObject] = useState(HIDDEN_DIALOG_OBJECT);

	const ProtectedRoute = ({ children }) => {
		if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
			// user is not authenticated
			// console.log('loggedInUser', loggedInUser);
			return <Navigate to='/' />;
		}
		return children;
	};

	const verifyUserSessionIsActive = async () => {
		try {
			const verifiedUser = await fetchVerifiedUser();
			if (verifiedUser.success && verifiedUser.data.email) {
				setLoggedInUser({ ...verifiedUser.data });
				return <Navigate to='/panel' />
			} else if (verifiedUser.status !== 200) {
				setLoggedInUser({});
			}
		} catch (error) {
			console.log('error:', error);
		}
	};

	const handleAuthLogoutPathOnLogIn = () => {
        if (loggedInUser == null || Object.keys(loggedInUser).length === 0) { return null; }
        return <Logout/>;
    }

	const appContextData = {
		loggedInUser,
		setLoggedInUser,
		alertObject,
		setAlertObject,
		dialogObject,
		setDialogObject,
	};

	useEffect(() => {
		verifyUserSessionIsActive();
	}, []);

	return (
		<div className="App">
			<CacheProvider value={cacheRtl}>
				<ThemeProvider theme={theme}>
					<AppContext.Provider value={appContextData}>
						<Router>
							<Routes>
							
								<Route
									exact
									path={'/login'}
									element={
										<Suspense fallback={<LinearProgress />}>
											<Login />
										</Suspense>
									}
								/>

								<Route
									exact
									path={'/logout'}
									element={	
										<ProtectedRoute>
											<Suspense fallback={<LinearProgress />}>
												<Logout />
											</Suspense>
										</ProtectedRoute>
									}
								/>
								<Route
									exact
									path={'/'}
									element={
										<Suspense fallback={<LinearProgress />}>
											<Home />
										</Suspense>
									}
								/>
								<Route
									exact
									path={'/panel'}
									element={
										<ProtectedRoute>
											<Suspense fallback={<LinearProgress />}>
												<Panel/>
											</Suspense>
										</ProtectedRoute>
									}
								/>
							</Routes>
						</Router>
						<AlertPopupContainer />
						<DialogPopupContainer />
					</AppContext.Provider>
				</ThemeProvider>
			</CacheProvider>
		</div>
	);
}

export default App;
